(function ($) {
    $.fn.bindWithDelay = function (type, data, fn, timeout, throttle) {
        if ($.isFunction(data)) {
            throttle = timeout;
            timeout = fn;
            fn = data;
            data = undefined;
        }
        fn.guid = fn.guid || ($.guid && $.guid++);
        return this.each(function () {
            var wait = null;

            function cb() {
                var e = $.extend(true, {}, arguments[0]);
                var ctx = this;
                var throttler = function () {
                    wait = null;
                    fn.apply(ctx, [e]);
                };
                if (!throttle) {
                    clearTimeout(wait);
                    wait = null;
                }
                if (!wait) {
                    wait = setTimeout(throttler, timeout);
                }
            }
            cb.guid = fn.guid;
            $(this).bind(type, data, cb);
        });
    };
})(jQuery);

function searchEnter() {
    $.ajaxSetup({
        headers: {
            'csrftoken': '{{ csrf_token() }}'
        }
    });
    $("#search").bindWithDelay("keypress", function (e) {
        if ((e.keyCode == 13) && (e.target.type != "textarea")) {
            var value = $('#search').val();
            var baseurl = window.location.protocol + "//" + window.location.host + "/";
            $.ajax({
                type: 'GET',
                url: baseurl + 'ajax/search',
                data: {
                    'search': value
                },
                success: function (response) {
                    $('.media-body').html(response);
                },
                error: function (response) {
                    $('.media-body').html(response);
                }
            });
        }
    });
}

function searchClick() {
    $.ajaxSetup({
        headers: {
            'csrftoken': '{{ csrf_token() }}'
        }
    });
    $("#buttonSearch").bindWithDelay("click", function (e) {
        var value = $('#search').val();
        var baseurl = window.location.protocol + "//" + window.location.host + "/";
        $.ajax({
            type: 'GET',
            url: baseurl + 'ajax/search',
            data: {
                'search': value
            },
            success: function (data) {
                $('.media-body').html(data['data']);
            }
        });
    });
}