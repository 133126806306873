baseurl = window.location.protocol + "//" + window.location.host + "/";
var waitingDialog = waitingDialog || (function ($) {
    'use strict';
    var $dialog = $('<div class="modal fade" data-backdrop="false" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:-50%; overflow-y:visible; style="background-color:rgba(0, 0, 0, 0);">' + '<div class="modal-dialog modal-m">' + '<div class="modal-content text-center" style="background-color:rgba(0, 0, 0, 0);">' + '<div class="modal-header"></div>' + '<div class="modal-body">' + '<div class="loading" style="margin-bottom:50px; color:#eeeeee;"><h3 style="margin:0;"></h3><img src="' + baseurl + 'dist/icons/chrome-touch-icon-192x192.png" style="width:100px;"></div>' + '</div>' + '</div></div></div>');
    return {
        show: function (message, options) {
            if (typeof options === 'undefined') {
                options = {};
            }
            if (typeof message === 'undefined') {
                if (document.getElementById('locale').value == 'pt') {
                    message = '';
                }
                if (document.getElementById('locale').value == 'en') {
                    message = '';
                }
            }
            var settings = $.extend({
                dialogSize: 'm',
                progressType: '',
                onHide: null
            }, options);
            $dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
            $dialog.find('.loading').attr('class', 'loading');
            $dialog.find('h3').text(message);
            if (typeof settings.onHide === 'function') {
                $dialog.off('hidden.bs.modal').on('hidden.bs.modal', function (e) {
                    settings.onHide.call($dialog);
                });
            }
            $dialog.modal();
        },
        hide: function () {
            $dialog.modal('hide');
        }
    };
})(jQuery);
$(function () {
    $.ajaxSetup({
        beforeSend: function (xhr) {
            waitingDialog.show();
        },
        complete: function (xhr) {
            setTimeout(function () {
                waitingDialog.hide()
            }, 500);
        }
    });
});