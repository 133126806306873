baseurl = window.location.protocol + "//" + window.location.host + "/";

var mounth= {
    '01' : 'January',
    '02' : 'February',
    '03' : 'March',
    '04' : 'April',
    '05' : 'May',
    '06' : 'June',
    '07' : 'July',
    '08' : 'August',
    '09' : 'September',
    '10' : 'October',
    '11' : 'November',
    '12' : 'Dezember'
}

var data = new Date();
var dia = String(data.getDate()).padStart(2, '0');
var mes = String(data.getMonth() + 1).padStart(2, '0');
var ano = data.getFullYear();
dataAtual = dia + '/' + mes + '/' + ano;
console.log(dataAtual);

function getLive() {
    $.ajax({
        type: 'GET',
        url: window.location.origin + '/ajax/livestream',
        data: {
            locale: $('#locale').val()
        },
        success: function (response) {
            if (response.success) {                
                    // // $('.bloco-fixo-live').removeClass('d-none');
                    // $('.bloco-fixo-live').find('.conteudo').append('<div class=evento-nome>' + response.data.name + '</div>');
                    // //$('.bloco-fixo-live').find('.conteudo').append('<div class=evento-data>' + response.data.date_start +'</div>');
                    // // $('.bloco-fixo-live').find('.conteudo').append('<div class=evento-data>' + 'February, 17th - 5PM UK time' +'</div>');
                    // // $('.bloco-fixo-live').find('.conteudo').on('click', function () {
                    //     //$('#liveModal').find('#liveModalLabel').text(response.data.name + ' - ' + response.data.date_start);
                    //     $('#liveModal').find('#liveModalLabel').text(response.data.name + ' - ' + mounth[response.data.date_start.substring(3,5)]+', ' +response.data.date_start.substring(0,2)+'th - ' +response.data.date_start.substring(11,13)+'PM UK time');
                    //     $('#liveModal').find('.modal-content').append('<div class="modal-body"><div class="video"><iframe id="live-event" src="' + response.data.url + '" frameborder="0" width="720" height="404" marginheight="0" marginwidth="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>');
                    //     $('body').addClass('modal-live');
                    // // });
                    // $('#liveModal').on('hidden.bs.modal', function () {
                    //     $('#liveModal').find('.modal-body').remove();
                    // });   
                    
                        $('#live-f22').append('<h5 class=evento-nome>' + response.data.name + ' - January, 16th - 10AM BR' + '</h5>');
                        $('#modal-f22').append('<div class="modal-body"><div class="video"><iframe id="live-event" src="' + response.data.url + '" frameborder="0" width="720" height="404" marginheight="0" marginwidth="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>');
                        $('#modal-f22-mb').append('<div class="modal-body"><div class="video"><iframe width="360" height="315" src="'+response.data.url+'" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>');

                        $('#live-f22-home').append('<h5 class=evento-nome>' + response.data.name + ' - January, 16th - 10AM BR' + '</h5>');
                        $('#modal-f22-home').append('<div class="modal-body"><div class="video"><iframe id="live-event" src="' + response.data.url + '" frameborder="0" width="720" height="404" marginheight="0" marginwidth="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>');
                        $('#modal-f22-home-mb').append('<div class="modal-body"><div class="video"><iframe width="360" height="315" src="'+response.data.url+'" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>');                        
                    
                }
        },
        error: function (response) {
            console.log('nao')
        }
    });
}

getLive();

$(document).ready(function () {
    setTimeout(function() {
        // body
        introJs().start();
    }, 1000);

    if($('body').hasClass('page-preview')){
        $('.bloco-fixo-live').removeClass('active');
    }

    $('.close-live').on('click', function () {
        $('body').removeClass('modal-live');
    });

    $('.close-bloco-live').on('click', function () {
        $('.bloco-fixo-live').removeClass('active');
    });

    var str = document.location.href;

    if (str.search("page=") != -1) {
        var edition_id = $("#edition_id").val();
        getPage(edition_id, identifica_pagina());
    }



    $(".modalzoom .zoombg").click(function () {
        $(".modalzoom").removeClass('active');
    });

    $('.page-preview').on('click', function () {
        //$(".page-preview").removeClass('active-modal');
        if ($('.page-preview').hasClass('closeModalOnClick')) {
            $(".page-preview").removeClass('active-modal');
            $(".page-preview").removeClass('closeModalOnClick');
        }
    });



    /* HOME */
    if ($(".page-home").length) {

        $("html,body").css('overflow', 'hidden');

        $(function () {

            var typeSpeed = 250,
                yourText = "frasson_gallery",
                anyText = "",
                posText = 0;

            var myInterval = setInterval(function () {

                anyText += yourText[posText];

                if (posText >= yourText.length) {
                    clearInterval(myInterval);

                } else {
                    $('.containerText').text(anyText);
                    posText++;
                };

            }, typeSpeed);
        });

        /*$(function () {

            var typeSpeed = 250,
                yourText = "frasson_gallery",
                anyText = "",
                posText = 0;

            var myInterval = setInterval(function () {

                anyText += yourText[posText];

                if (posText >= yourText.length) {
                    clearInterval(myInterval);

                } else {
                    $('.logo-modal .containerTextModal').text(anyText);
                    posText++;
                };

            }, typeSpeed);
        });*/

        if (sessionStorage.primeirasessao == 1) {

            if($(document).width()>992){
                $('#modalHome').modal('show');
            }
            $('#modalHome .modal-body').addClass('show-up');
            jQuery(".wrap").removeClass('show-modal');
            if (sessionStorage.modalNone == 1) {
                $('#modalHome').modal('hide');
                setTimeout(function() {
                    sessionStorage.setItem("modalNone", "0");
                }, 1000);
                
            }
            jQuery("#page-1").hide();
            jQuery("html,body").css('overflow', 'visible');
            jQuery("#page-2 #revista-destaque").addClass('fadeInUp');


            jQuery("#header").addClass('animated slideInDown');
            jQuery("#row1-home > .flex-wrap").addClass('animated fadeInLeftDown');
            jQuery("#row1-home #box-capa").addClass('animated fadeInRightDown');
        } else {

            setTimeout(function () {

                jQuery("#page-1").addClass('fadeOut').addClass('some');

                jQuery("html,body").css('overflow', 'visible');
                jQuery(window).scrollTop(0);

                jQuery("#intro").css('height', '0');
                jQuery("#page-2 #revista-destaque").addClass('fadeInUp');
                jQuery(".logo-intro").css("display", "none");


                jQuery("#header").addClass('animated slideInDown');
                jQuery("#row1-home > .flex-wrap").addClass('animated fadeInLeftDown');
                jQuery("#row1-home #box-capa").addClass('animated fadeInRightDown');

                $('#modalHome').modal('show');
                $('#modalHome .modal-body').addClass('show-up');
                jQuery(".wrap").removeClass('show-modal');

                /***/

                $('.slide-portifolio').slick('unslick');

                setTimeout(function() {
                    $('.slide-portifolio').slick({
                        infinite: false,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false,
                        arrows: true,
                        adaptiveHeight: true,
                        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>',
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                  slidesToShow: 1,
                                  slidesToScroll: 1,
                                }
                            }
                        ]
                    });
                }, 1000);

            }, 8000 /*5500 Tempo*/ );



            sessionStorage.setItem("primeirasessao", "1");

        }

        $("#Latest h3.title,#Latest .slide-portifolio").addClass('animated fadeOut')


        if ($(document).width() < 768) {
            $('.slide-portifolio').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                adaptiveHeight: true,
                nextArrow: '<button type="button" class="slick-next"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>'
            });
        } else {
            $('.slide-portifolio').slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: false,
                arrows: true,
                adaptiveHeight: true,
                nextArrow: '<button type="button" class="slick-next"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>'
            });
        }

        /** Efeitos de transição **/

        if (!($('body').hasClass('page-home'))) {
            var waypoints = $('#row2').waypoint({
                handler: function (direction) {
                    if (direction == 'up') {
                        $('#row2 .content-texto').addClass('fadeOut').removeClass('fadeInUp');
                        $('#row2 .image img').addClass('fadeOut').removeClass('fadeInRight');
                    }
                    if (direction == 'down') {
                        $('#row2 .content-texto').removeClass('fadeOut').addClass('fadeInUp');
                        $('#row2 .image .imagem1').removeClass('fadeOut').addClass('fadeInUp');
                        $('#row2 .image .imagem2').removeClass('fadeOut').addClass('fadeInDown');
                    }


                },
                offset: 600
            });
        }



    }
    /** Fim - Efeitos de transição **/
    /* FIM HOME */


    /* HEADER */
    $(".ativa-busca").click(function () {
        $("#listagem-busca").toggleClass("show")
        $(".campo-busca .form-control").focus();
    });

    $(".navbar-close,#sidemenu .menu-right").click(function () {
        $("#sidemenu").addClass('fadeOut').removeClass('fadeIn');
        $("body").toggleClass('no-scroll');
    });

    $(".navbar-toggler").click(function () {
        $("body").addClass('no-scroll');
        $("#sidemenu").addClass('fadeIn').removeClass('fadeOut');
    });

    $(".fundo-busca").click(function () {
        $("#listagem-busca").toggleClass("show")
    });

    $('#login-form').keypress(function (e) {
        if ((e.keyCode == 13) && (e.target.type != "textarea")) {
            login();
            return false;
        }
    });

    var timerhomer;
    var iScrollPos = 0;

    if ($("body").hasClass('page-preview') == false) {

        $(window).scroll(function () {

            if ($(window).scrollTop() > $('#header').height()) {

                $('#header').addClass('scroll');

                if ($(this).scrollTop() > iScrollPos) {

                    $('#header').removeClass('active');

                } else {

                    $('#header').addClass('active');

                    clearTimeout(timerhomer);
                    timerhomer = setTimeout(function () {
                        $('#header').removeClass('active')
                    }, 3000);

                }

                iScrollPos = $(this).scrollTop();

            } else {
                $('#header').removeClass('scroll');
                $('#header').addClass('active');
                clearTimeout(timerhomer);
            }

            if ($(this).scrollTop() > 50) {
                $('.page-preview #header').addClass('d-none');
            } else {
                $('.page-preview #header').removeClass('d-none');
            }
        });
    }
    /* FIM HEADER */


    /* PAGINA SOBRE */
    if ($(".page-about").length) {

        $('#scrollEquipe').click(function () {
            $('body').animate({
                scrollTop: $('#equipe').offset().top
            }, 800);
        });

        $("#biografias .textos .text-about").addClass('d-none');


        /** Carregamento inicial **/
        $("#biografias .box-who").first().addClass('ativo');
        $("#biografias .textos .text-about").first().removeClass('d-none');

        $("#biografias .box-who").click(function () {
            $("#biografias .box-who").removeClass('ativo');
            $(this).addClass('ativo');
            var idbio = $(this).data("about");
            $("#biografias .textos .text-about").addClass('d-none');
            $("#biografias .textos .text-about-" + idbio).removeClass('d-none');

            if ($(window).width() > 1440) {
                //var marginTopItem= $("#biografias .box-who.ativo").offset().top-1409;
                var marginTopItem = $(this).offset().top - 1400;

                $('html, body').animate({
                    scrollTop: $(".box-who.ativo").offset().top - 100
                }, 900);

            } else if ($(window).width() > 1280) {
                var marginTopItem = $(this).offset().top - 1432;

                $('html, body').animate({
                    scrollTop: $(".box-who.ativo").offset().top - 100
                }, 900);
            } else if ($(window).width() > 1024) {
                var marginTopItem = $(this).offset().top - 1532;

                $('html, body').animate({
                    scrollTop: $(".box-who.ativo").offset().top - 100
                }, 900);

            } else if ($(window).width() > 992) {
                var marginTopItem = $(this).offset().top - 1964;

                $('html, body').animate({
                    scrollTop: $(".box-who.ativo").offset().top - 100
                }, 900);

            } else {
                var marginTopItem = 0;

                $('html, body').animate({
                    scrollTop: $("#nome-" + idbio).offset().top - $("#header").height()
                }, 900);
            }

            $("#biografias .textos .text-about").css('margin-top', 0);
            $("#biografias .textos .text-about-" + idbio).css('margin-top', marginTopItem);
        });

        $("#biografias .textos .text-about-1").css('margin-top', 0);
    }
    /* FIM SOBRE */


    /* PAGINA PORTFOLIO */
    if ($(".page-portifolios").length) {

        $('.filtro .form-check-input').bind('change', function () {
            var ativos = 0;
            $('.listagem-portifolios .item-portifolio').addClass('col-0');
            $(".filtro .form-check-input").each(function (index) {
                if ($(this).prop('checked')) {
                    ativos = 1;
                    $('.listagem-portifolios .' + $(this).val()).removeClass('col-0');
                }
            });

            if (ativos == 0) {
                $('.listagem-portifolios .item-portifolio').removeClass('col-0');
            }
        });
    }

    if ($(".page-portifolio").length) {
        $('.slideportifolio').slick({
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            variableWidth: true
        });
    }
    /* FIM PORTFOLIO */


    $("#botao-login").on("click", function () {
        login();
    });

    $("#inputEstado").change(function () {
        var estado = $("#inputEstado option:selected").text();
        if (estado == "Outro") {
            $("#pais").removeClass("col-sm-6").addClass("col-sm-4");
            $("#pais").addClass("d-block").removeClass("d-none");
            $("#estado").addClass("d-block").removeClass("d-none");
            $("#cidade").addClass("d-block").removeClass("d-none");
            $(".campocidade").addClass("d-none").removeClass("d-block");
        } else {
            $(".campocidade").addClass("d-block").removeClass("d-none");
            $("#pais").addClass("d-none").removeClass("d-block");
            $("#estado").addClass("d-none").removeClass("d-block");
            $("#cidade").addClass("d-none").removeClass("d-block");
        }
    });

    var estado = $("#inputEstado option:selected").text();
    if (estado == "Outro") {
        $("#pais").removeClass("col-sm-6").addClass("col-sm-4");
        $("#pais").addClass("d-block").removeClass("d-none");
        $("#estado").addClass("d-block").removeClass("d-none");
        $("#cidade").addClass("d-block").removeClass("d-none");
        $(".campocidade").addClass("d-none").removeClass("d-block");
    } else {
        $(".campocidade").addClass("d-block").removeClass("d-none");
        $("#pais").addClass("d-none").removeClass("d-block");
        $("#estado").addClass("d-none").removeClass("d-block");
        $("#cidade").addClass("d-none").removeClass("d-block");
    }

    $(".box-editardados .cancelar").click(function () {
        if (estado == "Outro") {
            $("#pais").removeClass("col-sm-6").addClass("col-sm-4");
            $("#pais").addClass("d-block").removeClass("d-none");
            $("#estado").addClass("d-block").removeClass("d-none");
            $("#cidade").addClass("d-block").removeClass("d-none");
            $(".campocidade").addClass("d-none").removeClass("d-block");
        } else {
            $(".campocidade").addClass("d-block").removeClass("d-none");
            $("#pais").addClass("d-none").removeClass("d-block");
            $("#estado").addClass("d-none").removeClass("d-block");
            $("#cidade").addClass("d-none").removeClass("d-block");
        }
    });

    $("#inputEstado").change(function () {
        var state = $("#inputEstado option:selected").text();
        if (state == "Outro") {
            $("#cities").fadeOut("slow");
            $("#pais").fadeIn("slow");
            $("#estado").fadeIn("slow");
            $("#cidade").fadeIn("slow");
        } else {
            $("#cities").fadeIn("slow");
            $("#pais").fadeOut("slow");
            $("#estado").fadeOut("slow");
            $("#cidade").fadeOut("slow");
        }
    });


    /* FORMULARIOS */
    if ($("#inputTelefone").length) {
        $('#inputTelefone').mask('+00 (00) 00000-0000');
    }
    if ($("#inputCep").length) {
        $('#inputCep').mask('00000-000');
    }

    $("#editardados").click(function () {
        $(this).hide();
        $(".box-editardados h5.title").addClass('d-none');
        $(".box-editardados h5.title-alterar").removeClass('d-none');
        $(".box-editardados .form-group input").prop('readonly', false);
        $(".box-editardados .form-group select").prop('disabled', false);
        $(".opcoes").hide();
        $(".box-editardados .rowdados").addClass('no-gutters');
        $(".box-editardados .button-editar").removeClass('d-none');
        $(".box-editardados .campoestado").addClass('col-sm-2').removeClass('col-sm-6');
        $(".box-editardados .campocidade").addClass('col-sm-4').removeClass('col-sm-6');
    });

    $("#editarsenha").click(function () {
        $("#boxeditarsenha").removeClass('d-none');
        $(".opcoes,.box-editardados").hide();
    });

    $("#boxeditarsenha .cancelar").click(function () {
        $("#boxeditarsenha").addClass('d-none');
        $(".opcoes,.box-editardados").show();
    });

    $(".btn-file").click(function () {
        var key = $(this).attr('data-key');
        $("#files-" + key).click();
    });

    $(".files").change(function () {
        var id = $(this).attr('id');
        var preview = document.getElementById('button-' + id);
        var file = document.getElementById(id).files[0];
        var reader = new FileReader();

        reader.addEventListener("load", function () {
            var tipo = file.type.split("/", 1);

            if (tipo[0] == "image") {
                jQuery('#button-' + id).css("background-image", "url(" + reader.result + ")");
                jQuery('#button-' + id).css("background-size", "100% 100%");
                jQuery('#button-' + id + ' svg').hide();
            } else if (file.type == "application/pdf") {
                jQuery('#button-' + id).css("background-image", "url(../web/assets/images/pdf.jpg)");
                jQuery('#button-' + id).css("background-size", "inherit");
                jQuery('#button-' + id + ' svg').hide();
            } else {
                jQuery('#button-' + id).css("background-image", "url(../web/assets/images/doc.jpg)");
                jQuery('#button-' + id).css("background-size", "inherit");
                jQuery('#button-' + id + ' svg').hide();
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    });

    $('#form-portfolio').bind('submit', function (e) {
        enviePortfolio();
        return false;
    });

    $('#newsletter_form').bind('submit', function (e) {
        newsletter();
        return false;
    });

    $('#reset_password_form').bind('submit', function (e) {
        sendResetPasswordEmail();
        return false;
    });
    /* FIM FORMULARIOS */


    /* PAGINA TRABALHO */
    if ($(".slide-trabalho").length) {
        $('.slide-trabalho').slick({
            infinite: false,
            slidesToShow: 1,
            variableWidth: true,
            arrows: true
        });
    }
    /* FIM PAGINA TRABALHO */

    $('.go-home a').on('click', function(){
        sessionStorage.setItem("modalNone", "1");
    });
    
});


/* *** INÍCIO Login no site *** */
function login() {
    var formData = new FormData($("#login-form")[0]);
    var locale = $('#locale').val();
    var baseurl = window.location.protocol + "//" + window.location.host + "/" + locale + "/";

    $.ajax({
        url: baseurl + 'login',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {

            if (!($.isEmptyObject(data.errors))) {
                printErrorMsg(data.errors);
            } else {
                location.reload();
            }
        },
    });
}
/* *** FIM Login no site *** */


/* *** INÍCIO Envio da mensagem no Contato *** */
function contact() {
    var locale = $('#locale').val();
    var formData = new FormData($("#form-contact")[0]);
    var baseurl = window.location.protocol + "//" + window.location.host + "/" + locale + "/";

    $.ajax({
        url: baseurl + 'contact/send',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        success: function (data) {
            if ($.isEmptyObject(data.errors)) {
                $('#form-contact').trigger("reset");
                $('p').remove('.valida');
                $('#modalMensagem').modal('show');
            } else {
                printErrorMsg(data.errors);
            }
        }
    });
}
/* *** FIM Envio da mensagem no Contato *** */


/* *** INÍCIO Envio de portfolio *** */
function enviePortfolio() {
    var locale = $('#locale').val();
    var formData = new FormData($("#form-portfolio")[0]);
    var baseurl = window.location.protocol + "//" + window.location.host + "/" + locale + "/";

    $.ajax({
        url: baseurl + 'ajax/portfolio/store',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        success: function (data) {
            if ($.isEmptyObject(data.errors)) {
                $('#form-portfolio').trigger("reset");
                $('.add-image').each(function () {
                    $(this).attr("style", "");
                    $(this).children().css("display", "");
                });
                $('p').remove('.valida');
                $('#modalPortfolioEnviado').modal('show');
            } else {
                printErrorMsg(data.errors);
            }
        },
    });
}
/* *** FIM Envio de portfolio *** */


/* *** INÍCIO Printa retorno mensagens de erro *** */
function printErrorMsg(msg) {
    if ($("p").hasClass("valida")) {
        $('p').remove('.valida');
    }
    $.each(msg, function (key, value) {
        if (key == 'files') {
            $('<p class="mensagem form valida">' + value + '</p>').insertAfter("[class='content-upload']");
        }

        $('<p class="mensagem form valida">' + value + '</p>').insertAfter("[name=" + key + "]");
    });
}
/* *** FIM Printa retorno mensagens de erro *** */


/* *** INÍCIO Filtro de cidades ao selecionar estado *** */
function selectCitiesOfState(stateID) {
    var baseurl = window.location.protocol + "//" + window.location.host + "/";
    var city = '#inputCidade';

    $.ajax({
        type: 'POST',
        url: baseurl + 'ajax/' + stateID + '/get-cities',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: stateID,
        cache: false,
        processData: false,
        contentType: false,
        error: function (response) {
            console.log('erro');
        },
        success: function (html) {
            $('#inputCidade').empty();
            $('#inputCidade').append(html);
        }
    });
}
/* *** FIM Filtro de cidades ao selecionar estado *** */


/* *** INÍCIO Envio da senha provisória para redefinir antiga *** */
function sendResetPasswordEmail() {
    var formData = new FormData($("#reset_password_form")[0]);
    var locale = $('#locale').val();
    var baseurl = window.location.protocol + "//" + window.location.host + "/" + locale + "/";

    $.ajax({
        url: baseurl + 'email/send/password',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        success: function (data) {
            if ($.isEmptyObject(data.errors)) {
                $('#reset_password_form').trigger("reset");
                $('p').remove('.valida');
                $('#modalEsqueciSenha').modal('toggle');
                $('#modalEmailSent').modal('show');
            } else {
                printErrorMsg(data.errors);
            }
        },
    });
}
/* *** FIM Envio da senha provisória para redefinir antiga *** */


/* *** INÍCIO Envio de Newsletter *** */
function newsletter() {
    var formData = new FormData($("#newsletter_form")[0]);
    var locale = $('#locale').val();
    var baseurl = window.location.protocol + "//" + window.location.host + "/" + locale + "/";

    $.ajax({
        url: baseurl + 'newsletter',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        success: function (data) {
            if ($.isEmptyObject(data.errors)) {
                $('#newsletter_form').trigger("reset");
                $('p').remove('.valida');
                $('#modalNewsletter').modal('show');
            } else {
                printErrorMsg(data.errors);
            }
        },
    });
}
/* *** FIM Envio de Newsletter *** */


/* *** INÍCIO Envio de orçamento *** */
function sendOrcamentoAjax() {
    var formData = new FormData($("#orcamento_form")[0]);
    var baseurl = window.location.protocol + "//" + window.location.host + "/";

    $.ajax({
        url: baseurl + 'ajax/orcamento/send',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        success: function (data) {
            if ($.isEmptyObject(data.errors)) {
                $('#orcamento_form').trigger("reset");
                $('p').remove('.valida');
                $('#modalConfirma').modal('show');
                $('.add-image').each(function () {
                    $(this).attr("style", "");
                    $(this).children().css("display", "");
                });
            } else {
                printErrorMsg(data.errors);
            }
        },
    });
}
/* *** FIM Envio de orçamento *** */


/* ***  INÍCIO Infinite Scroll Portfolios *** */
function fetchPosts() {
    var page = $('.endless-pagination').data('next-page');
    if (page !== null) {
        clearTimeout($.data(this, "scrollCheck"));
        $.data(this, "scrollCheck", setTimeout(function () {
            $.get(page, function (data) {
                $('.listagem-portifolios').append(data.portfolios);
                $('.endless-pagination').data('next-page', data.next_page);
            });
        }, 350));
    }
}
/* *** FIM Infinite Scroll Portfolios *** */

if ($("#texto-intro").hasClass('home')) {

    var scrollTopoffsetUm = $('#revista-destaque').height() / 2 + 100;
    var scrollTopoffsetDois = $('#revista-destaque').height() / 2 + 100;
    var scrollTopoffsetVideo = 1100;

    if ($(document).width() < 768) {
        var scrollTopoffsetUm = 100;
        var scrollTopoffsetVideo = 300;
    }

    $(window).scroll(function () {

        if ($(window).scrollTop() > scrollTopoffsetUm) {
            //alert("rolagem chegou ao elemento");
            if (!($("#Latest h3.title").hasClass('fadeInUp'))) {
                $("#Latest h3.title").removeClass('fadeOut').addClass('fadeInUp');
                $("#Latest .slide-portifolio").removeClass('fadeOut').addClass('fadeInUp');
            }
        } else {
            $("#Latest h3.title").removeClass('fadeInUp').addClass('fadeOut');
            $("#Latest .slide-portifolio").removeClass('fadeInUp').addClass('fadeOut');
        }


        if ($(window).scrollTop() > scrollTopoffsetVideo) {
            //alert("rolagem chegou ao elemento");
            if (!($("#texto-intro > .content-texto .texto").hasClass('fadeInRight'))) {
                $('#texto-intro > .content-texto img').removeClass('fadeOut').addClass('fadeInUp');
                $('#texto-intro > .content-texto video').removeClass('fadeOut').addClass('fadeInUp');
                $('#texto-intro > .content-texto .texto').removeClass('fadeOut').addClass('fadeInRight');
            }

            if (!($(".video-iframe").hasClass('visible'))) {
                $(".video-iframe").addClass('visible');
            }

        } else {
            $('#texto-intro > .content-texto img').addClass('fadeOut').removeClass('fadeInUp');
            $('#texto-intro > .content-texto video').addClass('fadeOut').removeClass('fadeInUp');
            $('#texto-intro > .content-texto .texto').addClass('fadeOut').removeClass('fadeInRight');

            $(".video-iframe").removeClass('visible');
        }
    });
}